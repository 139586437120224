<template>

  <div class="wrapper">

    <Slider></Slider>

    <div id="dp" class="stick">
      <hr class="mt-0 mb-0">

      <div class="highlight-box pt-0">
        <section class="bets-menu d-flex justify-content-between mt-1 mb-1 ">

          <div v-if="highlights" class="w-20 p-1  active text-center">
            Highlights
          </div>
          <div v-else class="w-20 p-1  text-blue text-center" @click="setHighlight">
            <a class="text-blue" style="text-decoration: none;">Highlights</a>
          </div>

          <div v-if="today" class="w-20 p-1 active text-center">
            Today
          </div>
          <div v-else class="w-20 p-1  text-blue text-center" @click="setToday">
            <a class="text-blue" style="text-decoration: none;">Today</a>
          </div>


          <div v-if="upcoming" class="w-20 p-1  active text-center">
            Upcoming
          </div>
          <div v-else class="w-20 p-1  text-blue text-center" @click="setUpcoming">
            <a class="text-blue" style="text-decoration: none;">Upcoming</a>
          </div>

          <div class="w-20 p-0   text-center" @click="goTo('print')">
            <i class="bi bi-printer text-light print-icon"></i>
          </div>


          <div class="w-20 p-1 px-3 text-blue d-none">
            Chap! Pick
          </div>

        </section>
      </div>

      <div class="buttons-sec nav-fill ">

        <div class="dropdown pr-1">
          <button id="sports-btn" class="btn btn-secondary  dropdown-toggle ddb" type="button" data-toggle="collapse"
                  data-target="#collapseSports" aria-expanded="false" aria-controls="collapseExample">
            Sports
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton"></div>
        </div>

        <div class="dropdown pr-1">
          <button @click="checkStick" id="countries-btn" class="btn btn-secondary  dropdown-toggle ddb" type="button"
                  data-toggle="collapse" data-target="#collapseCountries" aria-expanded="false"
                  aria-controls="collapseExample">
            Countries
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">

          </div>
        </div>

        <div class="dropdown pr-1">
          <button id="leagues-btn" class="btn btn-secondary  dropdown-toggle ddb" type="button" data-toggle="collapse"
                  data-target="#collapseLeagues" aria-expanded="false" aria-controls="collapseExample">
            Leagues
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">

          </div>
        </div>

        <div class="dropdown">
          <button id="market-btn" class="btn btn-secondary  dropdown-toggle ddb" type="button" data-toggle="collapse"
                  data-target="#collapseMarkets" aria-expanded="false" aria-controls="collapseExample">
            Markets
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton"></div>
        </div>

      </div>

      <div id="accordion" class="mb-2">
        <Sports></Sports>
        <Countries></Countries>
        <Markets></Markets>
        <Leagues></Leagues>
      </div>

    </div>

    <Games v-bind:highlights="highlights" v-bind:today="today" v-bind:upcoming="upcoming"
           v-bind:sport="sport_id"></Games>

    <BottomNavigation></BottomNavigation>
    <!-- Launcher -->
    <!-- <button id="launch" type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter" hidden>
         Launch demo modal
     </button>
     <div class="modal depo-modal" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
         <div class="depo-modal modal-dialog modal-dialog-centered" role="document">
             <div class="modal-content p-5">
                 <div>
                     <div class="text-center modal-head mb-4">
                        <img src="/img/check-circle-fill.svg" class="reg-svg">  Registration Successful
                     </div>
                     <div class="text-center karibu-text">
                         PATA Ksh.2,500 KARIBU GIFT Now!!
                     </div>
                     <div class="text-center mb-4 f-depo">
                         Deposit Ksh.50 and Above to Claim
                     </div>

                     <div class="text-center mb-5">
                         <a href="/deposit" class="depo-btn py-2 px-5 text-light"> Go to Deposit (0 Fee)</a>
                     </div>
                 </div>


             </div>
         </div>
     </div> -->

    <!-- End Launcher -->
  </div>

</template>

<script>


const Slider = () => import('./Slider.vue')
// const Boosted = () => import('./Boosted.vue')
const Markets = () => import('./accordion/markets.vue')
const Sports = () => import('./accordion/sports.vue')
const Countries = () => import('./accordion/countries.vue')
const Leagues = () => import('./accordion/leagues.vue')
const Games = () => import('./Games.vue')
const BottomNavigation = () => import('./BottomNavigation.vue')
import jQuery from "jquery";

const $ = jQuery;

export default {
  name: 'Sport',
  components: {
    Leagues,
    Countries,
    // Boosted,
    Markets,
    Sports,
    Slider,
    Games,
    BottomNavigation
  },

  data: function () {
    return {
      highlights: this.$store.state.current_tab == 'highlights' ? true : false,
      upcoming: this.$store.state.current_tab == 'upcoming' ? true : false,
      today: this.$store.state.current_tab == 'today' ? true : false,
      sub_page: '',
      mqttClient: '',
    }
  },
  mounted() {

    //this.$store.dispatch("setSportID",1);
    this.$store.dispatch("setHour", -1);
    //this.$store.dispatch("setMarketID",1);
    this.$store.dispatch("setCompetitionID", 0);
    // this.$store.dispatch("setFixture", {});
    // reset game filter
    //this.$store.dispatch("resetAllGames");
    // var vm = this;
    this.reloadProfile();
    //  if(vm.getProfile().np == 1 && vm.getProfile().b1 == 0 ){
    //     document.getElementById("launch").click();
    //   }

    this.$store.dispatch("setCurrentPage", 'sport');
  },
  computed: {
    betslip_count: function () {

      return this.$store.state.betslip.total

    },
    current_sub_page: function () {

      return this.$store.state.current_sub_page;
    },
    sport_id: function () {

      return this.$store.state.sport_id;

    },

  },
  methods: {
    checkStick: function () {
      document.getElementById('dp').classList.remove('stick');
    },
    setUpcoming: function () {
      this.$store.dispatch("setCurrentTab", "upcoming");
      this.highlights = false;
      this.today = false;
      this.upcoming = true;
    },
    setToday: function () {
      this.$store.dispatch("setCurrentTab", "today");
      this.highlights = false;
      this.today = true;
      this.upcoming = false;
    },
    setHighlight: function () {
      this.$store.dispatch("setCurrentTab", "highlights");
      this.highlights = true;
      this.today = false;
      this.upcoming = false;
    },
    setSubPage: function (page) {

      this.$store.dispatch("setCurrentSubPage", page);
      this.sub_page = page;

    },
    isHighlight: function () {

      return this.highlights
    },
    isToday: function () {

      return this.today
    },
    isUpcoming: function () {

      return this.upcoming
    },

    geSubPageActiveClass: function (subpage) {

      return subpage === this.sub_page ? 'active' : "";

    },
    setSport: function (sportID) {

      this.$store.dispatch("setSportID", sportID);

    }
  },
}


window.addEventListener("scroll", () => {
  //var viewportOject = $('#infoY').offset().top - 50;
  var scrollPos = $(document).scrollTop();
  //console.log("vv"+viewportOject);
  //console.log("SS"+scrollPos);
  if (scrollPos >= 150) {
    // $("#dp").addClass("stick");
    //alert("I am here");
  } else {
    // $("#dp").removeClass("stick");
  }
})

</script>